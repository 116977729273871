/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import "@js/parts/fadeIn";
import {
  initializeMap,
  setupMarkers,
  setupScrollListener,
  setupLinkMouseEvents
} from "@js/parts/mapInit";
import UIkit from "uikit";

import "flowbite";
import "@js/parts/navigation";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

// Map globals
window.mapState = {
  map: null,
  linksWithCoordinates: [],
  markers: [],
  highlightedMarkerIndex: null,
  initializeMap,
  setupMarkers,
  setupScrollListener,
  setupLinkMouseEvents
};
