let lastClickedMarkerIndex = null;

function initializeMap({
  containerId,
  accessToken,
  center = [10.4515, 51.1657],
  zoom = 3,
  padding = { right: 0, left: window.innerWidth * 0.33 },
  style = "mapbox://styles/mapbox/light-v11",
  scrollToLink = false
}) {
  mapboxgl.accessToken = accessToken;
  window.mapState.scrollToLink = scrollToLink;
  return new mapboxgl.Map({
    container: containerId,
    style,
    center,
    zoom,
    padding
  });
}

function createMarker({
  lng,
  lat,
  color,
  linkIndex,
  onClick,
  onMouseEnter,
  onMouseLeave
}) {
  const marker = new mapboxgl.Marker({ color })
    .setLngLat([lng, lat])
    .addTo(window.mapState.map);

  const element = marker.getElement();
  element.style.cursor = "pointer";

  if (lng < 0) {
    console.log(lng, lat);
  }

  // Attach event listeners if provided
  if (onMouseEnter)
    element.addEventListener("mouseenter", () => onMouseEnter(linkIndex));
  if (onMouseLeave)
    element.addEventListener("mouseleave", () => onMouseLeave(linkIndex));
  if (onClick) element.addEventListener("click", () => onClick(linkIndex));

  return marker;
}

function setupMarkers({ bounds }) {
  const handleMarkerClick = index => {
    if (lastClickedMarkerIndex === index) {
      window.location.href = window.mapState.linksWithCoordinates[index].href;
      return;
    }
    lastClickedMarkerIndex = index;
    setActiveLink(index);
  };

  window.mapState.linksWithCoordinates.forEach((link, index) => {
    const lng = parseFloat(link.getAttribute("data-lng"));
    const lat = parseFloat(link.getAttribute("data-lat"));

    if (!isNaN(lng) && !isNaN(lat)) {
      const marker = createMarker({
        lng,
        lat,
        color: "#000",
        linkIndex: index,
        onClick: () => handleMarkerClick(index),
        onMouseEnter: () => highlightMarker({ index, color: "#f00" }),
        onMouseLeave: () => highlightMarker({ index, color: "#000" })
      });

      window.mapState.markers[index] = marker;
      bounds.extend([lat, lng]);
    } else {
      console.warn(`Invalid coordinates for link at index ${index}`);
      window.mapState.markers[index] = null;
    }
  });

  if (!bounds.isEmpty()) {
    // window.mapState.map.fitBounds(bounds, {
    //   padding: { right: 0, left: window.innerWidth * 0.66 },
    //   minZoom: 15
    // });
  }
}

function highlightMarker({ index, color }) {
  const marker = window.mapState.markers[index];
  if (!marker) {
    console.warn(`No marker found for index ${index}`);
    return;
  }

  // Reset z-index for all markers
  window.mapState.markers.forEach(m => {
    if (m) m.getElement().style.zIndex = 1;
  });

  // Set higher z-index and change color for the highlighted marker
  const element = marker.getElement();
  element.style.zIndex = 10;
  element.querySelector("svg path").style.fill = color;
}

function setActiveLink(activeIndex) {
  window.mapState.linksWithCoordinates.forEach((link, index) => {
    link.classList.remove("selected");
    link.style.fontWeight = "";

    // Reset marker color
    highlightMarker({ index, color: "#000" });
  });

  const activeLink = window.mapState.linksWithCoordinates[activeIndex];
  activeLink.classList.add("selected");
  activeLink.style.fontWeight = "bold";

  // scroll to the corresponding space/map link
  if (window.mapState.scrollToLink) {
    const linkOffset = activeLink.getBoundingClientRect().top + window.scrollY;
    const viewportCenter = window.innerHeight / 2;
    const scrollPosition = linkOffset - viewportCenter + (activeLink.offsetHeight / 2);
  
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth"
    });
  }

  const lng = parseFloat(activeLink.getAttribute("data-lng"));
  const lat = parseFloat(activeLink.getAttribute("data-lat"));
  if (!isNaN(lng) && !isNaN(lat)) {
    window.mapState.map.flyTo({
      center: [lng, lat],
      zoom: 4,
      padding: {
        left: window.innerWidth * 0.3,
        right: 0,
        top: 0,
        bottom: 0
      },
      duration: 2000
    });

    highlightMarker({ index: activeIndex, color: "#f00" });
  }
}

function setupScrollListener() {
  const isMobile = window.innerWidth < 768;
  if (!isMobile) return;

  const onScroll = () => {
    const viewportBottom = window.innerHeight;
    let closestIndex = 0;
    let closestDistance = Infinity;

    window.mapState.linksWithCoordinates.forEach((link, index) => {
      const rect = link.getBoundingClientRect();
      const distance = Math.abs(
        viewportBottom - rect.bottom - viewportBottom * 0.5
      );
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });

    setActiveLink(closestIndex);
  };

  window.addEventListener("scroll", onScroll);
  setActiveLink(0);
}

function setupLinkMouseEvents() {
  const flyToLocation = (link, index, color) => {
    highlightMarker({ index, color });
    const lng = parseFloat(link.getAttribute("data-lng"));
    const lat = parseFloat(link.getAttribute("data-lat"));
    if (!isNaN(lng) && !isNaN(lat)) {
      window.mapState.map.flyTo({
        center: [lng, lat],
        zoom: 4,
        padding: {
          left: window.innerWidth * 0.3,
          right: 0,
          top: 0,
          bottom: 0
        },
        duration: 2000
      });
    }
  };

  window.mapState.linksWithCoordinates.forEach((link, index) => {
    link.addEventListener("mouseenter", () =>
      flyToLocation(link, index, "#f00")
    );
    link.addEventListener("mouseleave", () =>
      highlightMarker({ index, color: "#000" })
    );
  });
}

export {
  initializeMap,
  setupMarkers,
  setupScrollListener,
  setupLinkMouseEvents
};
